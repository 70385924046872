import { PageHeader } from "./PageHeader";
import { FooterContent } from "./FooterContent";
import { LandingPageContent } from "./LandingPageContent";

export function LandingPage() {
  return (
    <>
      <main className="page-main landing-page">
        <div className="page-main-inner">
          <PageHeader />
          <LandingPageContent />
          <div className="page-footer-spacer"></div>
        </div>
      </main>
      <footer className="page-footer">
        <div className="page-footer-inner">
          <FooterContent />
        </div>
      </footer>
    </>
  );
}
