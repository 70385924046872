import { HeroTitle } from "@/modules/look/HeroTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type HeroSideBySideProps = {
  headline: string;
  subheadlines: string[];
  callToActionContent: React.ReactNode;
  mediaContent?: React.ReactNode;
  signupFormContent?: React.ReactNode;
};

export const HeroSideBySide = ({
  headline,
  subheadlines,
  callToActionContent,
  mediaContent,
  signupFormContent,
}: HeroSideBySideProps) => {
  return (
    <Box
      sx={{
        maxWidth: "1280px",
        display: "grid",
        // border: "solid 1px red",
        gridTemplateColumns: {
          xs: "1fr",
          lg: mediaContent ? "1fr auto" : "1fr",
        },
        alignItems: { xs: "center", lg: mediaContent ? "start" : "center" },
        justifyItems: { xs: "center", lg: mediaContent ? "start" : "center" },
        gap: "2rem",
        mx: "auto",
      }}
    >
      {/* Cell: headline and description */}
      <Box
        sx={{
          maxWidth: "100%",
          textAlign: {
            xs: "center",
            lg: mediaContent ? "left" : "center",
          },
          alignSelf: "end",
        }}
      >
        <Box sx={{ my: 4 }}>
          <Box sx={{ my: 1 }}>
            <img src="/assets/logo.svg" height="36px" alt="scriptea" />
          </Box>
          <HeroTitle>{headline}</HeroTitle>

          <Typography
            variant="body1"
            sx={{
              mt: 1,
              fontSize: 20,
              textTransform: "none",
              maxWidth: "40em",
            }}
            mx={{
              sm: "auto",
              // lg: 0
            }}
            component="div"
          >
            {subheadlines.map((subheadline, i) => (
              <Box key={i} sx={{ my: 1 }} component="p">
                {subheadline}
              </Box>
            ))}
          </Typography>
        </Box>
      </Box>

      {/* Cell: screen recording */}

      <Box
        sx={{
          mx: { xs: "auto" },
          gridColumn: { xs: null, lg: "2" },
          gridRow: { xs: null, lg: "1 / 3" },
        }}
      >
        {mediaContent}
      </Box>

      {/* Cell: Demo button & signup form */}
      <Box sx={{ textAlign: { xs: "center", lg: "left" } }}>
        <Box sx={{ mb: 8 }}>{callToActionContent}</Box>

        {signupFormContent && (
          <Box sx={{ mx: "auto" }}>{signupFormContent}</Box>
        )}
      </Box>
    </Box>
  );
};
