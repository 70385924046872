import { NextLinkButton } from "@/lib/next-mui/NextLinkButton";
import { css } from "@emotion/css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { HeroSideBySide } from "./HeroSideBySide";

const headline = `Learn languages with podcasts`;
const subheadlines = [
  `Immerse yourself in the language by listening to what interests you.`,
  `Take advantage of the latest AI technology to generate a transcript and translations.`,
];

// const mainPoints = [
//   {
//     title: "Immersion",
//     detail:
//       "Immerse yourself in the language by listening to what interests you.",
//   },
//   {
//     title: "Vocabulary",
//     detail: "Expose yourself to new vocabulary as used in a real context.",
//   },
//   {
//     title: "Pronounciation",
//     detail:
//       "Get used to the pronounciation, so that you can understand more over time.",
//   },
//   {
//     title: "Sentence structure",
//     detail: "Get a feel for the sentence structure.",
//   },
//   {
//     title: "Transcript",
//     detail:
//       "Practice your comprehension skills by following along in the transcript.",
//   },
//   {
//     title: "Translations",
//     detail:
//       "Understand the meaning of any sentence by getting translations into a wide variety of languages.",
//   },
//   {
//     title: "Dictionary",
//     detail:
//       "Look up individual words in the built-in dictionary (currently only available for German).",
//   },
// ];

// const faq = [
//   {
//     question: "What is ScripTea?",
//     answer:
//       "ScripTea is a web app where you can listen to podcasts, read along in the automatically generated transcript, get machine translations for sentences, and look up individual words in the built-in dictionary.",
//   },
//   {
//     question: "Who is it for?",
//     answer:
//       "ScripTea is for anyone who would like to learn or improve their knowledge of a language. It is best suited for learners at the intermediate level or above, but it can be useful for beginners as well.",
//   },
//   {
//     question: "What languages are supported?",
//     answer:
//       "ScripTea supports a multitude of languages. German has the best support, since there is a built-in German-English dictionary. Transcription is available for English, French, German, Hindi, Portuguese, Spanish, Danish, Dutch, Italian, Japanese, Korean, Norwegian, Polish, Swedish, Tamil, Chinese, Indonesian, Russian, Turkish, Ukrainian, Catalan, Arabic, Finnish, Vietnamese, Hebrew, Greek, Malay, Czech, Romanian, Hungarian, Thai, Urdu, Croatian, Bulgarian, Lithuanian, Maori, Welsh, Slovak, Persian, Latvian, Serbian, Azerbaijani, Slovenian, Kannada, Estonian, Macedonian, Icelandic, Armenian, Nepali, Bosnian, Kazakh, Swahili, Galician, Marathi, Afrikaans, Belarusian and Tagalog.",
//   },
//   {
//     question: "What can I listen to?",
//     answer:
//       "You can use ScripTea with any podcast that has an RSS feed. If a podcast is published to work in all podcatchers (podcast player apps), then it works in ScripTea as well. You can browse the trending podcasts, search in a directory, or add any RSS feed URL manually.",
//   },
//   {
//     question: "How much does it cost?",
//     answer:
//       "It's free for now. Soon, I plan to introduce a 10€/month subscription, which you can use for transcription, translations or other services. Depending on how you use the app, I estimate that 10€ will be enough for about 70 minutes of audio if you request a translation for every sentence and around 600 minutes if you only need transcriptions. Detailed pricing will be added soon. You can listen to unlimited audio if you disable the transcription, essentially using ScripTea as a regular podcatcher app.",
//   },
//   {
//     question: "How is this different from Whisper or other AI services?",
//     answer:
//       "ScripTea uses Whisper and other services internally. In addition, it provides a convenient user interface specifically for listening to podcasts.",
//   },
//   {
//     question: "How can I sign up for an account?",
//     answer:
//       'ScripTea is still a work-in-progress, user accounts will be added later. For now, your data, like subscribed podcasts, listening progress, bookmarks, are stored on your device and can be synchronized to another device via the "Workspaces" feature available in the menu.',
//   },
//   {
//     question: "Is there an iOS or Android app?",
//     answer:
//       'There is no native iOS or Android app, but you can use ScripTea on your phone in your web browser or install it as a Progressive Web App. To do this on Android, open Chrome, visit https://scriptea.app/app, open the menu and select "Install app". On iOS, open Safari, visit https://scriptea.app/app, tap the share icon in the middle, and pick "Add to Home Screen".',
//   },
//   {
//     question: "How can I contact you?",
//     answer:
//       'You can email levente@scriptea.app or use the "Contact" menu item in the app. If you have any issues with the app, have an idea on how to improve it, or have any other feedback or question, please don\'t hesitate to contact me.',
//   },
// ];

// const EarlyAccessSignupForm = dynamic(() => import("./EarlyAccessSignupForm"));

export const LandingPageContent = () => {
  return (
    <div
      className={css`
        box-sizing: border-box;
        padding-top: 1rem;
      `}
    >
      <HeroSection />
      {/* <MainPointsSection /> */}
      {/* <FaqSection /> */}

      {/* <GoToAppButton /> */}
    </div>
  );
};

const GoToAppButton = () => {
  return (
    <NextLinkButton href="/app" variant="contained" size="large">
      Go to App
    </NextLinkButton>
  );
};

const HeroSection = () => {
  return (
    <HeroSideBySide
      headline={headline}
      subheadlines={subheadlines}
      callToActionContent={<GoToAppButton />}
      // mediaContent={
      // <Box sx={{ m: 4 }}>
      //   <Video src="/assets/demo.webm" width={384} height={768} />
      // </Box>
      // }
      // signupFormContent={
      //   <Box sx={{ minHeight: 176 }}>
      //     <EarlyAccessSignupForm />
      //   </Box>
      // }
    />
  );
};

// const MainPointsSection = () => {
//   return (
//     <Section>
//       {mainPoints.map(({ title, detail }, index) => (
//         <Box key={index} sx={{ my: 8 }} component={"div"}>
//           <Typography variant="h4" sx={{ mb: 1 }} component="h5">
//             {title}
//           </Typography>
//           <Typography component="p">{detail}</Typography>
//         </Box>
//       ))}
//     </Section>
//   );
// };

// const FaqSection = () => {
//   return (
//     <Section>
//       <Typography
//         variant="h4"
//         sx={{
//           mb: 1,
//         }}
//         component="h4"
//       >
//         Frequently Asked Questions
//       </Typography>
//       {faq.map(({ question, answer }, index) => (
//         <Box key={index} sx={{ my: 4 }} component="div">
//           <Typography variant="h5" sx={{ mb: 1 }} component="h5">
//             {question}
//           </Typography>
//           <Typography component="p">{answer}</Typography>
//         </Box>
//       ))}
//     </Section>
//   );
// };

// const Section = ({ children }) => {
//   return (
//     <Box
//       sx={{
//         my: 4,
//         textAlign: "left",
//         maxWidth: "40em",
//         mx: "auto",
//         borderTop: "solid 2px var(--purple-light)",
//         py: 4,
//         px: 3,
//       }}
//     >
//       {children}
//     </Box>
//   );
// };
