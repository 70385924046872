import { Link } from "@/lib/next-mui/Link";
import { css } from "@emotion/css";

export const FooterContent = () => {
  return (
    <div
      className={css`
        box-sizing: border-box;
        > * {
          margin-bottom: 0.5em;
        }
        > *:last-child {
          margin-bottom: 0;
        }
      `}
    >
      <nav
        className={css`
          text-align: center;
          font-size: smaller;
          a {
            color: inherit;
          }
        `}
      >
        <ul
          className={css`
            padding: 0;
            margin: 0;
            list-style: none;
            li {
              display: inline-block;
              margin-right: 0.5em;
            }
            li > a {
              color: inherit;
              text-decoration: none;
            }
            li > a:hover {
              color: #595fc8;
            }
          `}
        >
          <li>
            <Link href="/imprint">Imprint</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};
