import Typography, { TypographyProps } from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

type HeroTitleProps = {
  children: React.ReactNode;
  sx?: TypographyProps["sx"];
  variant?: TypographyProps["variant"];
} & TypographyProps;

export const HeroTitle = ({ children, sx, variant }: HeroTitleProps) => {
  const theme = useTheme();
  const light = theme.palette.mode === "light";
  return (
    <Typography
      variant={variant ?? "h3"}
      component="div"
      sx={{
        fontWeight: "bold",
        fontSize: "4rem",
        lineHeight: 1.2,
        color: "#5D9943",
        background: light
          ? "-webkit-linear-gradient(86deg, #532757, #bf82c3)"
          : "-webkit-linear-gradient(86deg, #c49fcb, #895a91)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        textWrap: "balance",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
