import Button from "@mui/material/Button";
import { NextLinkComposed } from "./Link";

type LinkButtonProps = {
  href: string;
  target?: "_blank";
  children: React.ReactNode;
  variant?: "text" | "contained" | "outlined";
  size?: "small" | "medium" | "large";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  sx?: React.CSSProperties;
};

export const NextLinkButton = (props: LinkButtonProps) => {
  const { href, children, variant, target, sx } = props;

  return (
    <Button
      variant={variant ?? "contained"}
      color="primary"
      component={NextLinkComposed}
      target={target}
      to={{
        pathname: href,
      }}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      size={props.size}
      sx={sx}
    >
      {children}
    </Button>
  );
};
