import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// import { LogoLink } from "../LogoLink";

export const PageHeader = () => {
  return (
    <AppBar
      position="static"
      color={"transparent"}
      sx={{ boxShadow: "none", textAlign: "left" }}
    >
      <Toolbar disableGutters>
        {/* <LogoLink /> */}
        {/* <NextLinkButton href="/app" variant="outlined">
          Go to app
        </NextLinkButton> */}
      </Toolbar>
    </AppBar>
  );
};
